import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LoadingSpinner } from '../components/LoadingSpinner'
import { goTo, LOGIN } from '../util/goTo'
import { authAxios, getToken, refreshToken, setToken } from '../util/api'
import { decodeState } from '../util/decoder'
import { connect, disconnect } from '../util/socketClient'
import { User } from '../types/user'

export const AuthContext = createContext<{
  user: User | undefined
  logout: (pathname?: string) => void
  refreshToken: () => Promise<string>
  accessToken: string
  webSocketConnected: boolean
}>({
  user: undefined,
  logout: () => {},
  refreshToken: () => Promise.resolve(''),
  accessToken: '',
  webSocketConnected: false
})
AuthContext.displayName = 'AuthContext'

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [user, setUser] = useState<User | undefined>()
  const [accessToken, setAccessToken] = useState<string>()
  const [webSocketConnected, setWebSocketConnected] = useState(false)

  const onConnected = () => setWebSocketConnected(true)

  const onDisconnected = () => setWebSocketConnected(false)

  const logout = (pathname = '/') => {
    authAxios()
      .get('/api/logout')
      .then(() => goTo({ pathname }))
  }

  useEffect(() => {
    if (!!getToken() && !webSocketConnected) {
      const refreshTokenInterval = setInterval(
        () => refreshToken().then(accessToken => setAccessToken(accessToken)),
        10000 // 10 seconds
      )
      const interval = setInterval(
        () => connect(getToken(), onConnected, onDisconnected),
        11000 // 11 seconds
      )
      return () => {
        clearInterval(interval)
        clearInterval(refreshTokenInterval)
      }
    }
  }, [webSocketConnected])

  useMemo(() => {
    if (!getToken()) {
      if (searchParams.get('state')) {
        const state = decodeState(searchParams.get('state'))
        if (state?.token) {
          setToken(state.token)
          setAccessToken(state.token)

          authAxios()
            .get<User>('/api/users/current')
            .then(({ data }) => {
              setUser(data)
              searchParams.delete('state')
              setSearchParams(searchParams)
              connect(state.token, onConnected, onDisconnected)

              if (
                searchParams.has('code') &&
                ['facebookConnect', 'instagramConnect', 'googleConnect'].includes(state.action)
              ) {
                const code = searchParams.get('code')
                navigate(`${state.pathname}?code=${code}&action=${state.action}`)
              }
            })
            .catch(() => goTo({ pathname: LOGIN }))

          return () => disconnect(onDisconnected)
        } else {
          goTo({ pathname: LOGIN })
        }
      } else {
        refreshToken().then(accessToken => {
          connect(accessToken, onConnected, onDisconnected)
          setAccessToken(accessToken)
          authAxios()
            .get<User>('/api/users/current')
            .then(({ data }) => setUser(data))
            .catch(() => goTo({ pathname: LOGIN }))
        })
        return () => disconnect(onDisconnected)
      }
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        accessToken,
        webSocketConnected,
        refreshToken: () =>
          refreshToken().then(accessToken => {
            setAccessToken(accessToken)
            return accessToken
          })
      }}
    >
      {user?.userId ? children : <LoadingSpinner />}
    </AuthContext.Provider>
  )
}
