import React, { ReactNode, useContext } from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { I18nContext } from '../providers/I18nProvider'

interface ModalDialogProps {
  show?: boolean
  onClose?: () => void
  onSubmit?: () => void
  submitDisabled?: boolean
  titleText?: string
  bodyText?: string
  children?: ReactNode
  continueText?: string
  submitButtonVariant?: string
  backdrop?: ModalProps['backdrop']
  stickyFooter?: boolean
}

export const ModalDialog: React.FC<ModalDialogProps> = ({
  show,
  onClose,
  onSubmit,
  submitDisabled = false,
  titleText,
  bodyText,
  children,
  continueText = '',
  submitButtonVariant = 'primary',
  backdrop = 'static',
  stickyFooter = false
}) => {
  const { translate } = useContext(I18nContext)

  return (
    <Modal show={show} onHide={onClose} centered backdrop={backdrop} className="px-sm-3 text-break">
      <Modal.Header closeButton={!!onClose} className="align-items-start">
        {titleText && <Modal.Title className="adv-w-90">{titleText}</Modal.Title>}
      </Modal.Header>
      {bodyText && (
        <Modal.Body>
          {typeof bodyText === 'string' ? (
            <p className="text-secondary my-1" dangerouslySetInnerHTML={{ __html: bodyText }} />
          ) : (
            <p className="text-secondary my-1">{bodyText}</p>
          )}
        </Modal.Body>
      )}
      {!!children && <Modal.Body>{children}</Modal.Body>}
      {onSubmit && (
        <Modal.Footer className={stickyFooter ? 'sticky-bottom bg-white' : ''}>
          <Button
            disabled={submitDisabled}
            onClick={event => {
              event.stopPropagation()
              onSubmit()
            }}
            className="w-100 my-1"
            variant={submitButtonVariant}
          >
            {continueText || translate('defaultValues.modalDialog.continue')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  )
}
