import React, { Fragment, useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { I18nContext } from '../providers/I18nProvider'
import { ModalDialog } from './ModalDialog'

export const CookiePolicy: React.FC = () => {
  const { translate, lang } = useContext(I18nContext)
  const [show, setShow] = useState(!document.cookie.includes('CookieConsent=true'))
  const [showPolicy, setShowPolicy] = useState(false)

  const setCookieConsentTrue = () => {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)

    document.cookie = `CookieConsent=true; path=/; expires=${expires.toUTCString()}; SameSite=Lax`
  }

  return (
    <Fragment>
      <Modal
        closeButton
        show={showPolicy}
        onHide={() => setShowPolicy(false)}
        fullscreen={true}
        style={{
          zIndex: 1056 // default bootstrap modal z-index is 1055
        }}
      >
        <Modal.Body className="p-0">
          <iframe
            title="Cookie policy"
            style={{ height: '95%' }}
            className="w-100"
            src={`/static/cookie_policy_${lang}.html`}
          />
        </Modal.Body>
      </Modal>

      <ModalDialog
        show={show}
        titleText={translate('cookieConsent.title')}
        bodyText={translate('cookieConsent.message')}
        continueText={translate('cookieConsent.buttonText')}
        onSubmit={() => {
          setCookieConsentTrue()
          setShow(false)
        }}
        onClose={() => setShowPolicy(true)}
      />
    </Fragment>
  )
}
