import { useContext, useEffect, useState } from 'react'
import { RestErrorHandlingContext } from '../../providers/RestErrorHandlingProvider'
import { UserProperty } from '../../types/userProperty'
import { Id } from 'adv/src/types/common'

type UserPropertyResponse = {
  value: string
  name: string
  userId: Id
}
export const useUserProperty = (propertyName: UserProperty) => {
  const { axios } = useContext(RestErrorHandlingContext)
  const [property, setProperty] = useState('')

  useEffect(() => {
    axios.get<UserPropertyResponse>(`/api/userproperty/${propertyName}`).then(({ data }) => setProperty(data.value))
  }, [propertyName])

  const save = (value: string) => axios.post(`/api/userproperty/${propertyName}`, { value })

  const saveWithVerification = (value: string, verificationCode: string) =>
    axios.post(`/api/userproperty/${propertyName}`, { value, verificationCode }).then(({ data }) => data)

  const sendVerificationCode = (value: string) => axios.post(`/api/userproperty/${propertyName}/verify`, { value })

  return { property, save, saveWithVerification, sendVerificationCode }
}
